<style lang="scss" scoped>
.page-device-list {
    .deviceQrcode {
        width: 80px;
        height: 80px;
    }

    .device-select {
        width: 120px;
    }

    /deep/ .el-dialog {
        width: 50%;
        max-width: 800px;
        min-width: 520px;
    }
}
</style>

<template>
    <div class="page-device-list">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <el-row>
                    <el-col :span="24">
                        <el-button type="primary" size="medium" icon="el-icon-plus"
                            @click="openImportDeviceDialog">导入设备</el-button>
                        <el-button type="primary" size="medium" icon="el-icon-s-custom"
                            @click="openAgentCollectDialog">设备领给代理</el-button>
                        <el-button type="danger" size="medium" icon="el-icon-refresh"
                            @click="recycleMultitermDeviceForAgent">设备从代理退回</el-button>
                        <el-button type="primary" size="medium" icon="el-icon-s-custom"
                            @click="openAllocationMerchantDialog">设备铺给商家</el-button>
                        <el-button type="danger" size="medium" icon="el-icon-refresh"
                            @click="recycleMultitermDevice">设备从商家回收</el-button>
                        <el-button type="warning" size="medium" icon="el-icon-download"
                            @click="upgradeMultitermDevice">设备升级</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="fr">
                <el-select class="device-select mrgr5 mrgb5" v-model="filter.provinceCode" placeholder="请选择省" clearable
                    @change="_search()" size="medium">
                    <el-option v-for="item in provinceOptions" :key="item.id" :label="item.name"
                        :value="item.id"></el-option>
                </el-select>
                <el-select class="device-select mrgr5 mrgb5" v-model="filter.cityCode" placeholder="请选择市" clearable
                    @change="_search()" size="medium">
                    <el-option v-for="item in cityOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <el-select class="device-select mrgr5 mrgb5" v-model="filter.areaCode" placeholder="请选择区/县" clearable
                    @change="_search()" size="medium">
                    <el-option v-for="item in countyOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <el-select class="device-select mrgr5 mrgb5" v-model="filter.status" placeholder="设备状态" clearable
                    @change="_search()" size="medium">
                    <el-option v-for="item in statusOptions" :key="item.value" :label="item.name"
                        :value="item.value"></el-option>
                </el-select>
                <el-select class="device-select mrgr5 mrgb5" v-model="filter.isOnline" placeholder="网络状态" clearable
                    @change="_search()" size="medium">
                    <el-option :value="true" label="在线"></el-option>
                    <el-option :value="false" label="离线"></el-option>
                </el-select>
                <el-select class="device-select mrgr5 mrgb5" v-model="filter.typeId" placeholder="设备型号" clearable
                    @change="_search()" size="medium">
                    <el-option v-for="item in deviceTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <el-select class="device-select mrgr5 mrgb5" v-model="filter.allocated" placeholder="分配状态" clearable
                    @change="_search()" size="medium">
                    <el-option :value="true" label="已分配"></el-option>
                    <el-option :value="false" label="未分配"></el-option>
                </el-select>
                <el-select class="device-select mrgr5 mrgb5" v-model="filter.agentId" filterable remote reserve-keyword
                    placeholder="代理(输入搜索)" :remote-method="renderAgentOptions" clearable @change="_search()"
                    size="medium">
                    <el-option v-for="item in agentItems" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <el-select class="device-select mrgr5 mrgb5" v-model="filter.merchantId" filterable remote reserve-keyword
                    placeholder="商家(输入搜索)" :remote-method="renderMerchantOptions" clearable @change="_search()"
                    size="medium">
                    <el-option v-for="item in merchantItems" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <el-input class="c-el-input mrgr5 mrgb5" placeholder="设备号" clearable v-model="filter.keywords"
                    size="medium"></el-input>
                <el-button type="primary" @click="_search()" size="medium"><i class="fa fa-search"></i> 查询</el-button>
            </div>
            <div class="clearb"></div>
        </div>
        <div>
            <el-alert class="mrgb10 colff0" type="error">
                未分配数量:{{ tableItems.unallocatedCount }}
                ,已分配数量:{{ tableItems.allocatedCount }}
                ,在线数量:{{ tableItems.onlineCount }}
                ,离线数量:{{ tableItems.offlineCount }}
                ,总收益:{{ tableItems.totalTurnover }}
            </el-alert>
            <el-table ref="multipleTable" :data="tableData" border fit highlight-current-row row-key="id"
                @selection-change="handleSelectionChange" @sort-change="sortChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="id" label="设备[版本号]" width="200">
                    <template slot-scope="scope">
                        {{ scope.row.id }}
                        <br />
                        {{ scope.row.type.name }}
                        <span v-if="scope.row.version"><br /> [{{ scope.row.version }}]</span>
                    </template>
                </el-table-column>
                <el-table-column label="设备二维码">
                    <template slot-scope="scope">
                        <a :href="getDeviceQrcodeUrl(scope.row.id)" target="_blank">
                            <img class="deviceQrcode" :src="getDeviceQrcodeUrl(scope.row.id)" />
                        </a>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态" sortable="custom">
                    <template slot-scope="scope">{{ scope.row.statusDesc }}</template>
                </el-table-column>
                <el-table-column prop="isOnline" label="是否在线" sortable="custom">
                    <template slot-scope="scope">
                        <el-tag type="success">{{ scope.row.isOnline ? "是" : "否" }}</el-tag>
                        <template slot-scope="scope">{{ scope.row.stock }}/{{ scope.row.emptySlots }}</template>
                    </template>
                </el-table-column>
                <el-table-column label="可借/可还">
                    <template slot-scope="scope">{{ scope.row.stock }}/{{ scope.row.emptySlots }}</template>
                </el-table-column>
                <el-table-column prop="lastReportTime" label="离线天数" sortable="custom">
                    <template slot-scope="scope">{{ scope.row.offTimeDay }}</template>
                </el-table-column>
                <el-table-column prop="networkCSQ" label="信号强度(0~31)" sortable="custom">
                    <template slot-scope="scope">{{ scope.row.networkCSQ }}</template>
                </el-table-column>
                <el-table-column prop="lastBorrowTime" label="上次租借时间" sortable="custom" width="160"></el-table-column>
                <el-table-column label="代理/商家" width="200">
                    <template slot-scope="scope">
                        <div v-if="scope.row.agent">代理:{{ scope.row.agent ? `${scope.row.agent.name}【${scope.row.agent.id}】` : "" }}</div>
                        <div v-if="scope.row.merchant">商家:{{ scope.row.merchant ? `${scope.row.merchant.name}【${scope.row.merchant.id}】` : "" }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="skipToRoute(`/device/details?id=${scope.row.id}`)">查看</el-dropdown-item>
                                <el-dropdown-item
                                    @click.native="skipToRoute(`/device/terminals?id=${scope.row.id}`)">查看终端</el-dropdown-item>
                                <el-dropdown-item @click.native="deleteDevice(scope.row.id)">删除</el-dropdown-item>
                                <el-dropdown-item @click.native="deviceUpgrade(scope.row)">升级设备</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="filter.page" :page-sizes="appPageSizes" :page-size="filter.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>

        <!-- 导入设备模态框 -->
        <el-dialog title="导入设备" :visible.sync="importDeviceDialog.show">
            <el-form label-width="200px" ref="importDeviceForm" :rules="importDeviceDialog.rules"
                :model="importDeviceDialog.form">
                <el-form-item label="品牌" prop="typeId">
                    <el-select v-model="importDeviceDialog.form.typeId" placeholder="请选择品牌">
                        <el-option label="请选择品牌" value></el-option>
                        <el-option :label="item.name" :value="item.id" v-for="item in deviceTypes"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数据(请选择xlsx、xls文件)" prop="file">
                    <input type="file" class="el-input__inner c-pw80" @change="fileChange($event)" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="importDeviceDialog.show = false">取 消</el-button>
                <el-button type="primary" @click="importDevice">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 分配代理模态框 -->
        <el-dialog title="设备领取给代理" :visible.sync="deviceAgentDialog.show">
            <el-form label-width="120px" ref="refDeviceAgentForm" :rules="deviceAgentDialog.rules"
                :model="deviceAgentDialog.form">
                <el-form-item label="代理" prop="typeId">
                    <el-select class="c-pw80" v-model="deviceAgentDialog.form.id" placeholder="请选择代理" filterable clearable
                        remote reserve-keyword :remote-method="renderAgentOptions" :disabled="deviceAgentDialog.disable">
                        <el-option label="请选择代理" value></el-option>
                        <el-option :label="item.name" :value="item.id" v-for="item in agentItems"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="deviceAgentDialog.show = false">取 消</el-button>
                <el-button type="primary" @click="deviceAgent">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 分配商家模态框 -->
        <el-dialog title="设备分配给商家" :visible.sync="allocationAgentDialog.show">
            <el-form label-width="120px" ref="allocationAgentForm" :rules="allocationAgentDialog.rules"
                :model="allocationAgentDialog.form">
                <el-form-item label="商户">
                    <el-select class="c-pw80" v-model="allocationAgentDialog.form.id" placeholder="请选择商户" filterable
                        clearable remote reserve-keyword :remote-method="renderMerchantOptions"
                        :disabled="allocationAgentDialog.disable" @change="renderMerchantPositionOptions('')">
                        <el-option label="请选择商户" value></el-option>
                        <el-option :label="item.name" :value="item.id" v-for="item in merchantItems"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="投放位置">
                    <el-select class="c-pw80" v-model="allocationAgentDialog.form.positionId" placeholder="请选择投放位置"
                        filterable clearable remote reserve-keyword :remote-method="renderMerchantPositionOptions"
                        :disabled="allocationAgentDialog.disable">
                        <el-option label="请选择投放位置" value></el-option>
                        <el-option :label="item.position" :value="item.id" v-for="item in merchantPositionItems"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="allocationAgentDialog.show = false">取 消</el-button>
                <el-button type="primary" @click="allocationAgent">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="弹出" :visible.sync="popupDialog.show">
            <el-form label-width="120px" ref="popupDialogForm" :rules="popupDialog.rules" :model="popupDialog.form">
                <el-form-item label="选择插槽" prop="slotNo">
                    <el-radio-group v-model="popupDialog.form.slotNo" style="display:flex;width:330px;flex-wrap: wrap;">
                        <div v-for="(item, index) in slotCount" :key="index">
                            <el-radio-button :label="item"></el-radio-button>
                        </div>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="popupDialog.show = false">取 消</el-button>
                <el-button type="primary" @click="popupClick">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="选择设备升级包" custom-class="c-el-dialog comp-upgrade-dialog" :visible.sync="showUpgradeDialog" :close-on-click-modal="false">
            <upgrade :ref="refUpgrade" :isUpdate="true" @selected="selectedUpgrade"></upgrade>
            <span slot="footer">
                <el-button size="small" @click="closeUpgrade()">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funMerchant from "@/api/merchant/index"
import * as funAgent from "@/api/agent/index"
import * as funDevice from "@/api/device/index"
import * as appRegion from "@/utils/app_region"
import upgrade from "./upgrades"
export default {
    name: "pageDeviceList",
    components: { upgrade },
    mixins: [enumConfigs],
    data() {
        return {
            refUpgrade: "refDeviceToUpgrade",
            tableItems: {},
            tableData: [],
            total: null,
            isPagination: false,
            filter: {
                page: 1,
                size: window.$common.appPageSize,
                keywords: "",
                startDate: null,
                endDate: null,
                status: null,
                merchantId: null,
                typeId: "",
                isOnline: "",
                agentId: "",
                allocated: "",
                provinceCode: "",
                cityCode: "",
                areaCode: "",
                sortDirection: 0,
                sortField: null,
                isActive: null
            },
            dateRange: [],
            merchantItems: [],
            agentItems: [],
            provinceOptions: [],
            cityOptions: [],
            countyOptions: [],
            addDeviceDialog: {
                show: false,
                form: {
                    num: "",
                    typeId: ""
                },
                rules: {
                    num: [{ required: true, message: "请输入数量", trigger: "blur" }],
                    typeId: [{ required: true, message: "请选择类型", trigger: "change" }]
                },
                loading: false
            },
            importDeviceDialog: {
                show: false,
                form: {
                    file: "",
                    typeId: ""
                },
                rules: {
                    typeId: [{ required: true, message: "请选择类型", trigger: "change" }],
                    file: [
                        { required: true, message: "请输入上传excel文件", trigger: "change" }
                    ]
                },
                loading: false
            },
            deviceAgentDialog: {
                show: false,
                form: {
                    id: ""
                },
                rules: {},
                loading: false,
                disable: false
            },
            merchantPositionItems: [],
            allocationAgentDialog: {
                show: false,
                form: {
                    id: "",
                    positionId: ""
                },
                rules: {},
                loading: false,
                disable: false
            },
            popupDialog: { //强制弹出 
                show: false,
                form: {
                    slotNo: ''
                },
                rules: {
                    slotNo: [{ required: true, message: "请选择插槽", trigger: "change" }],
                },
                loading: false,
            },
            showUpgradeDialog: false,
            deviceId: '',
            slotCount: null,
            multipleSelection: [],
            deviceTypes: []
        }
    },
    computed: {
        statusOptions() {
            return this.arrDeviceStatus
        }
    },
    activated() {
        if (this.$route.query.id) {
            this.filter.merchantId = Number(this.$route.query.id)
        }
        if (this.$route.query.agentId) {
            this.filter.agentId = Number(this.$route.query.agentId)
        }
        if (this.$route.query.keywords) {
            this.filter.keywords = this.$route.query.keywords
        }
        if (this.$route.query.isOnline == "1" || this.$route.query.isOnline == "0") {
            this.filter.isOnline = Boolean(Number(this.$route.query.isOnline))
        }
        this.getDataList()
        // 重新加载数据
        this.renderMerchantOptions("")
        this.renderAgentOptions("")
        this.renderMerchantPositionOptions("")
    },
    created() {
        this.env = process.env.NODE_ENV === "production"
            ? process.env.VUE_APP_FLAG === "pro"
                ? "测试服"
                : "正式服"
            : "正式服"
        if (this.$route.query.id) {
            this.filter.merchantId = Number(this.$route.query.id)
        }
        if (this.$route.query.agentId) {
            this.filter.agentId = Number(this.$route.query.agentId)
        }
        if (this.$route.query.keywords) {
            this.filter.keywords = this.$route.query.keywords
        }
        if (this.$route.query.isOnline == "1" || this.$route.query.isOnline == "0") {
            this.filter.isOnline = Boolean(Number(this.$route.query.isOnline))
        }
        this.renderMerchantOptions("")
        this.renderAgentOptions("")
        this.renderDeviceTypes()
        this.provinceOptions = appRegion.getProvinces()
        this.getDataList()
    },
    watch: {
        "allocationAgentDialog.form.id"() {
            this.renderMerchantPositionOptions("")
        },
        "filter.provinceCode"(provinceCode) {
            this.filter.cityCode = ""
            this.filter.areaCode = ""
            this.cityOptions = appRegion.getCitys(provinceCode)
            this.countyOptions = []
        },
        "filter.cityCode"(cityCode) {
            this.filter.areaCode = ""
            this.countyOptions = appRegion.getCountys(cityCode)
        }
    },
    methods: {
        handleSizeChange(val) {
            this.filter.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.filter.page = val
            this.getDataList()
        },
        getDataList() {
            if (this.filter.sortField == "lastReportTime") {
                this.filter.sortDirection = this.filter.sortDirection == 1 ? 0 : 1
            }
            window.$common.fullLoading()
            funDevice.GetDeviceList(this.filter).then(response => {
                window.$common.closeFullLoading()
                this.tableItems = response
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        async renderAgentOptions(query) {
            await funAgent.GetAgentAll({ keywords: query }).then(res => {
                this.agentItems = res
            })
        },
        async renderMerchantOptions(query) {
            await funMerchant.GetAllMerchants({ keywords: query }).then(res => {
                this.merchantItems = res
            })
        },
        async renderMerchantPositionOptions(query) {
            if (!this.allocationAgentDialog.form.id) {
                this.allocationAgentDialog.form.positionId = ""
                this.merchantPositionItems = []
                return false
            }
            await funMerchant.PositionList({
                merchantId: this.allocationAgentDialog.form.id,
                keywords: query,
                page: 1,
                size: 10000
            }).then(res => {
                this.merchantPositionItems = res.items
            })
        },
        async renderDeviceTypes() {
            await funDevice.GetAllDeviceType().then(res => {
                this.deviceTypes = res
            })
        },
        openAddDeviceDialog() {
            this.addDeviceDialog.show = true
        },
        openImportDeviceDialog() {
            this.importDeviceDialog.show = true
        },
        saveDevice() {
            this.$refs.addDeviceForm.validate(async (valid) => {
                if (valid) {
                    funDevice.GenerateDevice({
                        typeId: this.addDeviceDialog.form.typeId,
                        num: this.addDeviceDialog.form.num
                    }).then(res => {
                        this.successMsg("创建成功")
                        this._search()
                        this.addDeviceDialog.show = false
                    })
                }
            })
        },
        // 设备领取给代理
        deviceAgent() {
            if (this.multipleSelection.length == 0) {
                this.errorMsg("请选择设备")
                return false
            }
            var deviceIds = []
            this.multipleSelection.forEach(item => {
                deviceIds.push(item.id)
            })
            this.$refs.refDeviceAgentForm.validate(async (valid) => {
                if (valid) {
                    // 分配给代理提交
                    await funDevice.Collect({
                        deviceIds: deviceIds,
                        agentOrMerchantId: this.deviceAgentDialog.form.id
                    }).then(res => {
                        this.successMsg("操作成功")
                        this.getDataList()
                        this.deviceAgentDialog.show = false
                    })
                    if (!this.deviceAgentDialog.form.id) {
                        this.warningMsg("请选择代理")
                    }
                    this.clearFun()
                }
            })
        },
        // 设备分配商家点击确定
        allocationAgent() {
            if (this.multipleSelection.length == 0) {
                this.errorMsg("请选择设备")
                return false
            }
            var deviceIds = []
            this.multipleSelection.forEach(item => {
                deviceIds.push(item.id)
            })
            this.$refs.allocationAgentForm.validate(async (valid) => {
                if (valid) {
                    if (!this.allocationAgentDialog.form.id) {
                        this.warningMsg("请选择商家")
                        return false
                    }
                    if (!this.allocationAgentDialog.form.positionId) {
                        this.warningMsg("请选择投放位置")
                        return false
                    }
                    // 分配给商户提交
                    window.$common.fullLoading()
                    await funDevice.Lay({
                        deviceIds: deviceIds,
                        agentOrMerchantId: this.allocationAgentDialog.form.id,
                        positionId: this.allocationAgentDialog.form.positionId
                    }).then(res => {
                        window.$common.closeFullLoading()
                        this.$message.success("操作成功")
                        this.getDataList()
                        this.allocationAgentDialog.show = false
                    })
                    this.clearFun()
                }
            })
        },
        // 清除模态框内容
        clearFun() {
            this.deviceAgentDialog.form.id = ""
            this.allocationAgentDialog.form.id = ""
            this.allocationAgentDialog.form.positionId = ""
        },
        //   在列表选择设备 否
        handleSelectionChange(val) {
            console.log(val)
            this.multipleSelection = val
        },
        // 分配给代理
        openAgentCollectDialog() {
            this.clearFun()
            if (this.multipleSelection.length == 0) {
                this.errorMsg("请选择设备")
                return false
            } else {
                this.deviceAgentDialog.show = true
            }
        },
        // 分配给商家
        openAllocationMerchantDialog() {
            this.clearFun()
            if (this.multipleSelection.length == 0) {
                this.errorMsg("请选择设备")
                return false
            } else {
                this.allocationAgentDialog.show = true
            }
        },
        // 设备从代理退回
        async recycleMultitermDeviceForAgent() {
            if (this.multipleSelection.length == 0) {
                this.errorMsg("请选择要退回的设备!")
                return false
            }
            var deviceIds = []
            this.multipleSelection.forEach(item => {
                deviceIds.push(item.id)
            })
            await this.confirm("确认要退回选中的设备吗?").then(() => {
                window.$common.fullLoading()
                funDevice.Return({ deviceIds }).then(res => {
                    window.$common.closeFullLoading()
                    this.successMsg("退回成功")
                    this.getDataList()
                })
            })
        },
        // 设备从商家回收
        async recycleMultitermDevice() {
            if (this.multipleSelection.length == 0) {
                this.errorMsg("请选择要回收的设备!")
                return false
            }
            var deviceIds = []
            this.multipleSelection.forEach(item => {
                deviceIds.push(item.id)
            })
            await this.confirm("确认要回收选中的设备吗?").then(() => {
                window.$common.fullLoading()
                funDevice.Recovery({ deviceIds }).then(res => {
                    window.$common.closeFullLoading()
                    this.successMsg("回收成功")
                    this.getDataList()
                })
            })
        },
        // 设备升级
        async upgradeMultitermDevice(row) {
            if (this.multipleSelection.length == 0) {
                this.errorMsg("请选择要升级的设备!")
                return false
            }
            this.showUpgradeDialog = true
        },
        // 选择升级包执行升级
        async selectedUpgrade(row) {
            if (!row || !row.fileUrl) {
                this.errorMsg("请选择升级包!")
                return false
            }
            var deviceIds = []
            this.multipleSelection.forEach(item => {
                deviceIds.push(item.id)
            })
            window.$common.fullLoading()
            await funDevice.Upgrade({ ids: deviceIds, fileUrl: row.fileUrl }).then(res => {
                window.$common.closeFullLoading()
                this.successMsg("升级请求发送成功")
                this.getDataList()
            })
        },
        // 设备升级
        async deviceUpgrade(row) {
            if (row) {
                this.$refs["multipleTable"].clearSelection()
                this.tableData.forEach(x => {
                    if (x.id == row.id) {
                        this.$refs["multipleTable"].toggleRowSelection(x, true)
                    } else {
                        this.$refs["multipleTable"].toggleRowSelection(x, false)
                    }
                })
                this.multipleSelection = [row]
                this.showUpgradeDialog = true
            }
        },
        // 关闭升级窗口
        closeUpgrade() {
            this.showUpgradeDialog = false
        },
        fileChange(e) {
            console.log(e)
            this.importDeviceDialog.form.file = e.target.files[0]
        },
        importDevice() {
            this.$refs.importDeviceForm.validate(async (valid) => {
                if (valid) {
                    let formData = new FormData()
                    formData.append("typeId", this.importDeviceDialog.form.typeId)
                    formData.append("file", this.importDeviceDialog.form.file)
                    await funDevice.DeviceImport(formData).then(res => {
                        this.alert(`总共${res.total}个设备，导入成功${res.successed}个，导入失败${res.failed}个`, "导入完成提示", { type: "info" }).then(() => {
                            this._search()
                            this.importDeviceDialog.show = false
                        })
                    })
                }
            })
        },
        getDeviceQrcodeUrl(id) {
            return `${this.uploadBaseUrl}/qrcode?text=${encodeURIComponent(`${this.uploadBaseUrl}?esno=${id}`)}`
        },
        openDetail(id) {
            this.$router.push(`/device/details/${id}`)
        },
        async deleteDevice(id) {
            await this.confirm(`确认要删除设备[${id}]吗?`).then(() => {
                funDevice.Delete({ id }).then(res => {
                    this.successMsg("删除成功")
                    this.getDataList()
                })
            })
        },
        async sortChange(e) {
            console.log(e)
            if (e.order) {
                this.filter.sortField = e.prop
                if (e.order == "ascending") {
                    this.filter.sortDirection = 0
                } else {
                    this.filter.sortDirection = 1
                }
            } else {
                this.filter.sortDirection = null
                this.filter.sortField = null
            }
            this.getDataList()
        },
        async restartDevice(id) {
            await this.confirm(`确认要重启设备${id}吗`).then(() => {
                funDevice.Restart({ id }).then(res => {
                    this.successMsg("发送重启命令成功")
                    setTimeout(() => {
                        this.getDataList()
                    }, 1000)
                })
            })
        },
        //   切换服务器
        async cutServer(id) {
            await this.confirm(`确认要切换到${this.env}吗`).then(() => {
                // let url = this.env === "正式服" ? "/api/services/app/Device/SwitchToProd" : "/api/services/app/Device/SwitchToUAT"
                if (this.env === "正式服") {
                    funDevice.SwitchToProd({ id }).then(res => {
                        this.successMsg("切换成功")
                        setTimeout(() => {
                            this.getDataList()
                        }, 1000)
                    })
                } else {
                    funDevice.SwitchToUAT({ id }).then(res => {
                        this.successMsg("切换成功")
                        setTimeout(() => {
                            this.getDataList()
                        }, 1000)
                    })
                }
            })
        },
        // 强制弹出输液宝
        popupDevice(id, slotCount) {
            // 设备Id
            this.slotCount = slotCount
            this.deviceId = id
            // console.log(id,slotCount)
            this.popupDialog.form.slotNo = ''
            this.popupDialog.show = true
        },
        popupClick() {
            // console.log(this.popupDialog.form.slotNo)
            this.$refs.popupDialogForm.validate(async (valid) => {
                if (valid) {
                    await funDevice.Eject({
                        slot: this.popupDialog.form.slotNo,
                        id: this.deviceId
                    }).then(res => {
                        this.popupDialog.show = false
                        this.successMsg("弹出成功")
                    })
                }
            })
        },
        _search() {
            this.filter.page = 1
            // this.filter.keywords = this.keywords
            this.getDataList()
        }
    }
}
</script>